<template>
  <div>
    <!-- Filters -->
    <media-list-filters
      :type-image-filter.sync="imageTypeFilter"
      :type-image-options="imageTypeOptions"
      :region-filter.sync="regionFilter"
      :region-options="regionOptions"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('media_create')"
                variant="primary"
                @click="isShowDropZone = !isShowDropZone"
              >
                <span class="text-nowrap">Add media</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="home mt-2">
          <b-col cols="6">
            <DropZone
              v-if="isShowDropZone"
              @drop.prevent="drop"
              @refetch-data="refetchData"
              @show-crop-modal="showModalCropImage"
            />
          </b-col>
          <!-- <b-col cols="4">
            <DropZoneBanner
              v-if="isShowDropZone"
              title="Banner Image"
              @drop.prevent="drop"
              @refetch-data="refetchData"
              @show-crop-banner-modal="showModalCropImageBanner"
            />
          </b-col> -->
          <b-col cols="6">
            <DropZoneVideo
              v-if="isShowDropZone"
              title="How it work video"
              @drop.prevent="drop"
              @refetch-data="refetchData"
              @show-crop-modal="showModalVideo"
            />
          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <DropZoneMultiple
              v-if="isShowDropZone"
              @drop.prevent="drop"
              @refetch-data="refetchData"
            />
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <!-- Table -->
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchBanners"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: preview -->
          <template #cell(preview)="data">
            <b-link @click="showModalCropImage(data.item)">
              <b-img
                v-if="data.item.media_type !== 'video'"
                height="60"
                width="100"
                :src="data.item.link"
              />
              <b-embed
                v-else
                height="60"
                width="100"
                type="video"
              >
                <source
                  :src="data.item.link"
                  type="video/mp4"
                >

              </b-embed>
            </b-link>
          </template>
          <!-- Column: Title -->
          <template #cell(title)="data">
            <span>
              {{ data.item.title }}
            </span>
          </template>
          <!-- Column: Type -->
          <template #cell(type)="data">
            <b-link>
              {{ mapTypeImage(data.item.type) }}
            </b-link>
          </template>
          <!-- Column: Region -->
          <template #cell(region_code)="data">
            <b-link>
              {{
                data.item.region_code ? data.item.region_code.toUpperCase() : ""
              }}
            </b-link>
          </template>
          <template #cell(createdAt)="data">
            <b-link>
              {{ formatDate(data.item.created_at) }}
            </b-link>
          </template>
          <!-- Column: Active -->
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(
                data.item
              )}`"
              class="text-capitalize"
            >
              {{
                data.item.products.length > 0 || data.item.type === 'banner_image'
                  ? "In use"
                  : "Not in use"
              }}
            </b-badge>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <b-link
              v-if="$can('media_delete') && data.item.products.length === 0"
              @click="deleteImage(data.item.id)"
            >
              Delete
            </b-link>
            <b-link
              v-if="data.item.type === 'banner_image'"
              @click="editBanner(data.item)"
            >
              Edit
            </b-link>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBanners"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <crop-image-modal
      :is-show-modal-crop-image.sync="isShowModalCropImage"
      :media-object="currentMedia"
      @refetch-data="refreshTable"
    />
    <crop-image-banner-modal
      :is-show-modal-crop-image-banner.sync="isShowModalCropImageBanner"
      :media-object="currentMedia"
      @refetch-data="refreshTable"
    />
    <process-video-modal
      :is-show-process-video-modal.sync="isShowVideoHowitWork"
      :media-object="currentMedia"
      @refetch-data="refreshTable"
    />
    <banner-list-edit
      :is-edit-banner-sidebar-active.sync="isEditBannerSidebarActive"
      :data-banner="dataBanner"
      @refetch-data="refreshTable"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BImg,
  BOverlay, BEmbed
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import useBannersList from '@/views/mediaManager/useMediaList'
import store from '@/store'
import ProcessVideoModal from '@/views/modal/ProcessVideoModal.vue'
import CropImageModal from '@/views/modal/CropImageModal.vue'
import CropImageBannerModal from '@/views/modal/CropImageBannerModal.vue'
import DropZone from '@/components/mediaManager/DropZone.vue'
import DropZoneBanner from '@/components/mediaManager/DropZoneBanner.vue'
import DropZoneMultiple from '@/components/mediaManager/DropZoneMultiple.vue'
import DropZoneVideo from '@/components/mediaManager/DropZoneVideo.vue'
import MediaListFilters from '@/components/mediaManager/MediaListFilters.vue'
import BannerListEdit from '@/components/mediaManager/BannerListEdit.vue'
import moment from 'moment'
import * as config from '../../utils/config'

export default {
  components: {
    BOverlay,
    BannerListEdit,
    DropZoneBanner,
    DropZoneVideo,
    ProcessVideoModal,
    MediaListFilters,
    DropZoneMultiple,
    DropZone,
    CropImageModal,
    CropImageBannerModal,
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    vSelect,
    BEmbed,
  },
  computed: {
    ...mapGetters('app', [
      'regionPriceOptions',
      'imageTypeOptions',
      'regionOptions',
    ]),
  },
  methods: {
    refreshTable() {
      this.showTable = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.refetchData()
        this.showTable = false
      }, 600)
    },
    mapTypeImage(type) {
      return this.imageTypeOptions.find(e => e.value === type).label
    },
    formatDate(time) {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    },
    deleteImage(id) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to delete this image.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            store.dispatch('media/deleteMedia', { id }).then(() => {
              this.refreshTable()
            })
          }
        })
    },
    editBanner(objectMedia) {
      this.dataBanner = {
        id: objectMedia.id,
        redirect_link: objectMedia.redirect_link,
      }
      this.isEditBannerSidebarActive = true
    },
    showModalCropImage(objectMedia) {
      if (objectMedia.id) {
        this.currentMedia = {
          id: objectMedia.id,
          title: objectMedia.title,
          region_code: {
            label: store.state.app.regionOptions.find(
              e => objectMedia.region_code === e.value,
            ).label,
            value: objectMedia.region_code,
          },
          type: {
            label: store.state.app.imageTypeOptions.find(
              o => objectMedia.type === o.value,
            ).label,
            value: objectMedia.type,
          },
          link: objectMedia.link,
        }
      } else {
        this.currentMedia = objectMedia
      }
      if (objectMedia.media_type === 'video') {
        this.isShowVideoHowitWork = true
      } else {
        this.isShowModalCropImage = true
      }
    },
    showModalCropImageBanner(objectMedia) {
      if (objectMedia.id) {
        this.currentMedia = {
          id: objectMedia.id,
          title: objectMedia.title,
          type: {
            label: store.state.app.imageTypeOptions.find(
              o => objectMedia.type === o.value,
            ).label,
            value: objectMedia.type,
          },
          link: objectMedia.link,
        }
      } else {
        this.currentMedia = objectMedia
      }
      this.isShowModalCropImageBanner = true
    },
    showModalVideo(objectMedia) {
      if (objectMedia.id) {
        this.currentMedia = {
          id: objectMedia.id,
          title: objectMedia.title,
          type: {
            label: store.state.app.imageTypeOptions.find(
              o => objectMedia.type === o.value,
            ).label,
            value: objectMedia.type,
          },
          link: objectMedia.link,
        }
      } else {
        this.currentMedia = objectMedia
      }
      this.isShowVideoHowitWork = true
    },
  },

  setup() {
    const currentMedia = ref({})
    const isShowDropZone = ref(false)
    const isShowModalCropImage = ref(false)
    const isShowModalCropImageBanner = ref(false)
    const isEditBannerSidebarActive = ref(false)
    const isShowVideoHowitWork = ref(false)
    const dataBanner = ref({})
    const {
      timeout,
      showTable,
      fetchBanners,
      tableColumns,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      imageTypeFilter,
      regionFilter,
    } = useBannersList()
    return {
      timeout,
      showTable,
      currentMedia,
      isShowDropZone,
      isShowModalCropImage,
      isShowModalCropImageBanner,
      isEditBannerSidebarActive,
      isShowVideoHowitWork,
      // showModalCropImage,
      // Sidebar
      config,
      fetchBanners,
      tableColumns,
      perPage,
      currentPage,
      totalBanners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      // Extra Filters
      imageTypeFilter,
      regionFilter,
      dataBanner,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.home {
  justify-content: center;
  justify-items: center;
  align-items: center;
}
</style>
