<template>
  <div
    :class="{ 'active-dropzone': active }"
    class="dropzone"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
  >
    <b-row class="justify-content-center">
      <validation-observer
        ref="typeForm"
        style="width: 70%;"
      >
        <b-row
          class="p-2 justify-content-center"
        >
          <b-col
            cols="4"
          >
            <b-form-group
              label="Region"
              label-for="type"
            >
              <validation-provider
                #default="{ errors }"
                name="Region"
                rules="required"
              >
                <v-select
                  v-model="region"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="regionOptions"
                  :clearable="true"
                  input-id="type"
                  @input="test"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <b-form-group
              label="Header type"
              label-for="type"
            >
              <validation-provider
                #default="{ errors }"
                name="Header type"
                rules="required"
              >
                <v-select
                  v-model="headerType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="imageTypeMultipleSelectOptions"
                  :clearable="true"
                  input-id="type"
                  @input="test"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col
            cols="4"
          >
            <label
              class="mt-2"
              @click="onSubmit"
            >
              Select Files
            </label>
            <input
              ref="uploadInput"
              multiple
              type="file"
              accept="image/*"
              @change="uploadImage"
            >
          </b-col>
        </b-row>
      </validation-observer>
    </b-row>
    <div
      style="background-color: #eee;"
    >
      <b-card-body
        style="height:120px; overflow-y:scroll;"
      >
        <b-row
          v-for="image in listImageUploaded"
          :key="image.title"
          class="justify-content-md-center"
        >
          {{ image.title + " " + image.status }}
        </b-row>
      </b-card-body>
    </div>

  </div>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  BFormGroup, BRow, BCol, BForm, BCardBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { checkImageExtensionFile } from '@/utils/utils'

export default {
  name: 'DropZone',
  components: {
    BCardBody,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapGetters('app', ['imageTypeMultipleSelectOptions']),
    ...mapGetters('app', ['regionOptions']),
  },
  methods: {
    test() {
    },
    onSubmit() {
      this.$refs.typeForm.validate().then(success => {
        if (success) {
          this.listImageUploaded = []
          this.$refs.uploadInput.click()
        }
      })
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const listImageUploaded = ref([])
    const headerType = ref(null)
    const region = ref(null)
    const active = ref(false)
    const toggleActive = () => {
      active.value = !active.value
    }
    const uploadImage = e => {
      const { files } = e.target
      Array.from(files).forEach(file => {
          if(!checkImageExtensionFile(file.name)){
            toast({
              component: ToastificationContent,
              props: {
                title: 'Please select a valid image (jpg, jpeg, png, gif)valid image!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          return
        }
      })

      if (files && files[0]) {
        // if (image.src) {
        //   URL.revokeObjectURL(image.src)
        // }
        Array.from(files).forEach(file => {
          if(!checkImageExtensionFile(file.name)){
            toast({
              component: ToastificationContent,
              props: {
                title: 'Please select a valid image (jpg, jpeg, png, gif)!',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          return
        }
          const form = new FormData()
          form.append('file', file)
          form.append('title', file.name.split('.')[0])
          form.append('region_code', region.value.value)
          form.append('type', headerType.value.value)
          store.dispatch('media/updloadMedia', form)
            .then(() => {
              listImageUploaded.value.push({
                title: file.name.split('.')[0],
                status: 'uploaded',
              })
              emit('refetch-data')
            })
            .catch(() => {
              listImageUploaded.value.push({
                title: file.name.split('.')[0],
                status: 'fail',
              })
            })
        })
      }
    }
    return {
      listImageUploaded,
      headerType,
      active,
      toggleActive,
      uploadImage,
      region,
    }
  },
}
</script>

  <style scoped lang="scss">
  .dropzone {
    width: 100%;
    height: 250px;
    border: 2px dashed #DB536A;
    background-color: #fff;
    transition: 0.3s ease all;
    label {
      margin-top: 3px;
      padding: 8px 12px;
      color: #fff;
      background-color: #DB536A;
      transition: 0.3s ease all;
    }
    input {
      display: none;
    }
  }
  .active-dropzone {
    color: #fff;
    border-color: #fff;
    background-color:#DB536A;
    label {
      background-color: #fff;
      color: #DB536A;
    }
  }
  </style>
