<template>
  <div
    :class="{ 'active-dropzone': active }"
    class="dropzone"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="toggleActive"
  >
    <!-- <span>Drag or Drop File {{ title }}</span>
    <span>OR</span> -->
    <label for="dropzoneFile">Select Image</label>
    <input
      id="dropzoneFile"
      ref="uploadInput"
      type="file"
      class="dropzoneFile"
      accept="image/*"
      @change="uploadImage"
    >
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { checkImageExtensionFile } from '@/utils/utils'

import {
  useToast,
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'DropZone',
  props: {
    title: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
    }
  },
  setup(props, { emit }) {
    const toast = useToast()
    const active = ref(false)
    const toggleActive = () => {
      active.value = !active.value
    }
    const image = ref({})
    const uploadImage = e => {
      const { files } = e.target
      if (files && files.length && files[0]) {
        if(!checkImageExtensionFile(files[0].name)){
          toast({
            component: ToastificationContent,
            props: {
              title: 'Please select a valid image (jpg, jpeg, png, gif)!',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return
        }
        if (image.src) {
          URL.revokeObjectURL(image.src)
        }
        const blob = URL.createObjectURL(files[0])
        const reader = new FileReader()
        reader.onload = event => {
          image.value = {
            title: files[0].name.split('.')[0],
            link: blob,
          }
          e.target.value = null
          emit('show-crop-modal', image.value)
        }
        reader.readAsArrayBuffer(files[0])
      }
    }
    return {
      active,
      toggleActive,
      uploadImage,
    }
  },
}
</script>

  <style scoped lang="scss">
  .dropzone {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
    border: 2px dashed #DB536A;
    background-color: #fff;
    transition: 0.3s ease all;
    label {
      padding: 8px 12px;
      color: #fff;
      background-color: #DB536A;
      transition: 0.3s ease all;
    }
    input {
      display: none;
    }
  }
  .active-dropzone {
    color: #fff;
    border-color: #fff;
    background-color:#DB536A;
    label {
      background-color: #fff;
      color: #DB536A;
    }
  }
  </style>
