import {
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import store from '@/store'
import {
  title,
} from '@core/utils/filter'

// Notification
import {
  useToast,
} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BNavItem } from 'bootstrap-vue'

export default function usebannersList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [{
    key: 'preview',
    sortable: false,
  },
  {
    key: 'title',
    sortable: false,
  },
  {
    key: 'media_type',
    sortable: false,
  },
  {
    key: 'type',
    sortable: false,
  },
  {
    key: 'region_code',
    sortable: false,
    label: 'region',
  },
  {
    key: 'createdAt',
    sortable: false,
  },
  {
    key: 'status',
    sortable: false,
  },
  {
    key: 'action',
  },
  ]
  const perPage = ref(10)
  const totalBanners = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const imageTypeFilter = ref(null)
  const regionFilter = ref(null)
  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBanners.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  const fetchBanners = (ctx, callback) => {
    store
      .dispatch('banner/fetchBanners', {
        search: searchQuery.value,
        type: imageTypeFilter.value,
        region: regionFilter.value,
        limit: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const banners = response.body.data
        callback(banners)
        totalBanners.value = response.body.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching banners list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const timeout = ref(null)
  watch([currentPage, perPage, searchQuery, imageTypeFilter, regionFilter], () => {
    showTable.value = true
         clearTimeout(timeout.value)

    timeout.value = setTimeout(() => {
      refetchData()
      showTable.value = false
    }, 2000)
  })
  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = item => {
    if (item.products.length === 0) {
      if (item.type === 'banner_image') {
        return 'success'
      }
      return 'warning'
    }
    if (item.products.length > 0) return 'success'
    return 'primary'
  }

  return {
    timeout,
    showTable,
    fetchBanners,
    tableColumns,
    perPage,
    currentPage,
    totalBanners,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    // Extra Filters
    imageTypeFilter,
    regionFilter,
  }
}
