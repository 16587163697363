var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"no-close-on-backdrop":true,"size":"xl","visible":_vm.isShowModalCropImage,"title":"Crop Image","cancel-title":"Close","centered":"","ok-title":"Accept","cancel-variant":"outline-secondary","hide-footer":true},on:{"hide":_vm.hideMethod,"change":function (val) { return _vm.$emit('update:is-show-modal-crop-image', val); },"ok":_vm.handleOk}},[_c('b-overlay',{attrs:{"show":_vm.loading,"rounded":"sm","spinner-variant":"primary","spinner-type":"grow","spinner-small":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"9","align-self":"center"}},[_c('b-row',{staticClass:"mb-1",attrs:{"align-h":"center"}},[_c('cropper',{ref:"cropper",staticStyle:{"height":"30rem"},attrs:{"default-size":_vm.defaultSize,"debounce":false,"src":_vm.mediaObject.id != null ? _vm.mediaObject.link : _vm.mediaObject.link,"canvas":{
                imageSmoothingQuality: 'high',
              }},on:{"change":_vm.change}})],1),_c('b-row',{attrs:{"align-v":"center"}},[_c('b-link',{on:{"click":_vm.zoomIn}},[_c('feather-icon',{staticClass:"ml-50",attrs:{"size":"20","icon":"ZoomInIcon"}})],1),_c('b-link',{on:{"click":_vm.zoomOut}},[_c('feather-icon',{staticClass:"ml-50",attrs:{"size":"20","icon":"ZoomOutIcon"}})],1),_c('b-link',{on:{"click":function($event){return _vm.rotate(-90)}}},[_c('feather-icon',{staticClass:"ml-50",attrs:{"size":"20","icon":"RotateCcwIcon"}})],1),_c('b-link',{on:{"click":function($event){return _vm.rotate(90)}}},[_c('feather-icon',{staticClass:"ml-50",attrs:{"size":"20","icon":"RotateCwIcon"}})],1),_c('b-link',{on:{"click":function($event){return _vm.flip(1, 0)}}},[_c('feather-icon',{staticClass:"ml-50",attrs:{"size":"20","icon":"LogInIcon"}})],1),_c('b-link',{on:{"click":function($event){return _vm.flip(0, 1)}}},[_c('feather-icon',{staticClass:"ml-50 rotated",attrs:{"size":"20","icon":"LogInIcon"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('validation-observer',{ref:"cropImageForm"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-form-group',{attrs:{"label":"Title","label-for":"tile"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tile"},on:{"input":_vm.changeInputForm},model:{value:(_vm.mediaObject.title),callback:function ($$v) {_vm.$set(_vm.mediaObject, "title", $$v)},expression:"mediaObject.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.imageTypeOptions,"clearable":false,"input-id":"type"},on:{"input":_vm.changeInputForm},model:{value:(_vm.mediaObject.type),callback:function ($$v) {_vm.$set(_vm.mediaObject, "type", $$v)},expression:"mediaObject.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Region","label-for":"region"}},[_c('validation-provider',{attrs:{"name":"Region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.regionOptions,"clearable":false,"input-id":"region"},on:{"input":_vm.changeInputForm},model:{value:(_vm.mediaObject.region_code),callback:function ($$v) {_vm.$set(_vm.mediaObject, "region_code", $$v)},expression:"mediaObject.region_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Selection","label-for":"selection"}},[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',[_c('b-form-input',{attrs:{"id":"selection","disabled":""},model:{value:(_vm.coordinates.width),callback:function ($$v) {_vm.$set(_vm.coordinates, "width", $$v)},expression:"coordinates.width"}})],1),_vm._v(" x "),_c('b-col',[_c('b-form-input',{attrs:{"id":"selection","disabled":""},model:{value:(_vm.coordinates.height),callback:function ($$v) {_vm.$set(_vm.coordinates, "height", $$v)},expression:"coordinates.height"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Preview","label-for":"preview"}},[_c('preview',{attrs:{"height":180,"image":_vm.result.image,"coordinates":_vm.result.coordinates}})],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex mt-2"},[_c('div',{staticClass:"ml-auto "},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.handleOk}},[_vm._v(" Confirm ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-2",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$emit('update:is-show-modal-crop-image', false)}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }