<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      size="xl"
      :visible="isShowProcessVideoModal"
      title="Process video"
      cancel-title="Close"
      centered
      ok-title="Accept"
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="hideMethod"
      @change="(val) => $emit('update:is-show-process-video-modal', val)"
      @ok="handleOk"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >

        <b-row>
          <b-col
            cols="9"
            align-self="center"
          >

            <b-row
              class="mb-1"
              align-h="center"
            >
              <b-embed
                type="video"
                aspect="16by9"
                controls
                allowfullscreen
              >
                <source
                  :src="mediaObject.id != null ? mediaObject.link : mediaObject.link"
                  type="video/mp4"
                >

              </b-embed>
            <!-- <video
              width="1000"
              controls
            >
              <source
                :src="mediaObject.link"
                type="video/mp4"
              >
              Your browser does not support HTML video.
            </video> -->
            </b-row>
          </b-col>
          <b-col cols="3">
            <validation-observer ref="cropImageForm">
              <b-form @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Title"
                  label-for="tile"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      id="tile"
                      v-model="mediaObject.title"
                      @input="changeInputForm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
                <b-form-group
                  label="Type"
                  label-for="type"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="mediaObject.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="imageTypeOptions"
                      :clearable="false"
                      input-id="type"
                      disabled
                      @input="changeInputForm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Region"
                  label-for="region"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Region"
                    rules="required"
                  >
                    <v-select
                      v-model="mediaObject.region_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="regionOptions"
                      :clearable="false"
                      input-id="region"
                      @input="changeInputForm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <div class="ml-auto ">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="handleSubmit"
            >
              Confirm
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="ml-2"
              @click="$emit('update:is-show-process-video-modal', false)"
            >
              Cancel
            </b-button>
          </div>

        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, VBModal, BFormGroup, BFormInput, BForm, BRow, BCol, BLink, BEmbed, BButton, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { required } from '@validations'
import VueVideoThumbnail from 'vue-video-thumbnail'
import * as config from '../../utils/config'

export default {
  components: {
    VueVideoThumbnail,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BLink,
    Preview,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    Cropper,
    BModal,
    BEmbed,
    BButton,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      config,
    }
  },
  props: {
    isShowProcessVideoModal: {
      type: Boolean,
      required: true,
    },
    mediaObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isChange: false,
      hideAccept: true,
      zoomPoint: 2,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      result: {
        coordinates: null,
        image: null,
      },
      image: null,
      extendTitle: '',
      required,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('app', ['imageTypeOptions']),
    ...mapGetters('app', ['regionOptions']),
  },
  methods: {
    hideMethod() {
      this.$emit('update:is-show-process-video-modal', false)
    },
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      }
    },
    changeInputForm() {
      console.log(this.isChange)
      this.isChange = true
    },
    change({ coordinates, image }) {
      this.isChange = false
      this.coordinates = coordinates
      this.result = {
        coordinates,
        image,
      }
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y)
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle)
    },
    zoomIn() {
      this.$refs.cropper.zoom(this.zoomPoint)
    },
    zoomOut() {
      this.$refs.cropper.zoom(0)
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.cropImageForm.validate().then(success => {
        if (!success) {
          return
        }
        if (success) {
          this.loading = true
          if (this.mediaObject.id) {
            const transferData = {
              title: this.mediaObject.title,
              region_code: this.mediaObject.region_code.value,
            }
            store.dispatch('media/updateRedirectLink', { id: this.mediaObject.id, media: transferData })
              .then(() => {
                this.loading = false
                this.$emit('update:is-show-process-video-modal', false)
                this.$emit('refetch-data')
              }).catch(e => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: e.response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.loading = false
              })
          } else {
            const form = new FormData()
            form.append('file', this.mediaObject.file)
            form.append(
              'title', this.mediaObject.title,
            )
            form.append('region_code', this.mediaObject.region_code.value)
            form.append('type', this.mediaObject.type.value)
            store.dispatch('media/updloadMedia', form)
              .then(response => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Success',
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
                this.loading = false
                this.$emit('update:is-show-process-video-modal', false)
                this.$emit('refetch-data')
              })
              .catch(e => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: e.response.data.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.loading = false
              })
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
</style>
