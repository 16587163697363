<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      size="xl"
      :visible="isShowModalCropImageBanner"
      title="Crop Image Banner"
      cancel-title="Close"
      centered
      ok-title="Accept"
      cancel-variant="outline-secondary"
      :hide-footer="true"
      @hide="$emit('update:is-show-modal-crop-image-banner', false)"
      @change="(val) => $emit('update:is-show-modal-crop-image-banner', val)"
      @ok="handleOk"
    >
      <b-overlay
        :show="loading"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-row align-v="center">
          <b-col cols="9">
            <b-row
              class="mb-1"
              align-h="center"
            >
              <cropper
                ref="cropper"

                :default-size="defaultSize"
                :debounce="false"
                style="height: 30rem"
                :src="mediaObject.id != null ? mediaObject.link : mediaObject.link"
                :canvas="{
                  imageSmoothingQuality: 'high',
                }"
                @change="change"
              />
            </b-row>
            <b-row align-v="center">
              <b-link @click="zoomIn">
                <feather-icon
                  size="20"
                  class="ml-50"
                  icon="ZoomInIcon"
                />
              </b-link>
              <b-link @click="zoomOut">
                <feather-icon
                  size="20"
                  class="ml-50"
                  icon="ZoomOutIcon"
                />
              </b-link>
              <b-link @click="rotate(-90)">
                <feather-icon
                  size="20"
                  class="ml-50"
                  icon="RotateCcwIcon"
                />
              </b-link>
              <b-link @click="rotate(90)">
                <feather-icon
                  size="20"
                  class="ml-50"
                  icon="RotateCwIcon"
                />
              </b-link>
              <b-link @click="flip(1, 0)">
                <feather-icon
                  size="20"
                  class="ml-50"
                  icon="LogInIcon"
                />
              </b-link>
              <b-link @click="flip(0, 1)">
                <feather-icon
                  size="20"
                  class="ml-50 rotated"
                  icon="LogInIcon"
                />
              </b-link>
            </b-row>
          </b-col>
          <b-col cols="3">
            <validation-observer ref="cropImageForm">
              <b-form @submit.stop.prevent="handleSubmit">
                <b-form-group
                  label="Title"
                  label-for="tile"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      id="tile"
                      v-model="mediaObject.title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </b-form-group>
                <b-form-group
                  label="Type"
                  label-for="type"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="mediaObject.type"
                      disabled
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="imageTypeOptions"
                      :clearable="false"
                      input-id="type"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Region"
                  label-for="region"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Region"
                    rules="required"
                  >
                    <v-select
                      v-model="mediaObject.region_code"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="regionOptions"
                      :clearable="false"
                      input-id="region"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Redirect link"
                  label-for="redirect_link"
                >
                  <b-form-input
                    id="link"
                    v-model="mediaObject.redirect_link"
                  />
                </b-form-group>
                <b-form-group
                  label="Selection"
                  label-for="selection"
                >
                  <b-row align-v="center">
                    <b-col>
                      <b-form-input
                        id="selection"
                        v-model="coordinates.width"
                        disabled
                      />
                    </b-col>
                    x
                    <b-col>
                      <b-form-input
                        id="selection"
                        v-model="coordinates.height"
                        disabled
                      />
                    </b-col>
                  </b-row>

                </b-form-group>
                <b-form-group
                  label="Preview"
                  label-for="preview"
                >
                  <preview
                    :height="180"
                    :image="result.image"
                    :coordinates="result.coordinates"
                  />
                </b-form-group>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
        <div class="d-flex mt-2">
          <div class="ml-auto ">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="handleOk"
            >
              Confirm
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="ml-2"
              @click="$emit('update:is-show-modal-crop-image-banner', false)"
            >
              Cancel
            </b-button>
          </div>

        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, VBModal, BFormGroup, BFormInput, BForm, BRow, BCol, BLink, BButton, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { required } from '@validations'
import * as config from '../../utils/config'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BLink,
    Preview,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    Cropper,
    BModal,
    BButton,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  setup() {
    const toast = useToast()
    return {
      toast,
      config,
    }
  },
  props: {
    isShowModalCropImageBanner: {
      type: Boolean,
      required: true,
    },
    mediaObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hideAccept: true,
      zoomPoint: 2,
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },
      result: {
        coordinates: null,
        image: null,
      },
      extendTitle: '',
      required,
      loading: false,
    }
  },
  computed: {
    ...mapGetters('app', ['imageTypeOptions']),
    ...mapGetters('app', ['regionOptions']),
  },
  methods: {
    defaultSize({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      }
    },
    change({ coordinates, image }) {
      this.coordinates = coordinates
      this.result = {
        coordinates,
        image,
      }
    },
    flip(x, y) {
      this.$refs.cropper.flip(x, y)
    },
    rotate(angle) {
      this.$refs.cropper.rotate(angle)
    },
    zoomIn() {
      this.$refs.cropper.zoom(this.zoomPoint)
    },
    zoomOut() {
      this.$refs.cropper.zoom(0)
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.cropImageForm.validate().then(success => {
        if (!success) {
          return
        }
        if (success) {
          this.loading = true
          const { canvas } = this.$refs.cropper.getResult()
          if (canvas) {
            const form = new FormData()
            canvas.toBlob(blob => {
              form.append('file', blob)
              form.append(
                'title', this.mediaObject.id ? `${this.mediaObject.title}_${this.coordinates.width}x${this.coordinates.height}` : `${this.mediaObject.title}`,
              )
              form.append('redirect_link', this.mediaObject.redirect_link)
              form.append('region_code', this.mediaObject.region_code.value)
              form.append('type', this.mediaObject.type.value)
              store.dispatch('media/updloadMedia', form)
                .then(response => {
                  this.toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Success',
                      icon: 'AlertTriangleIcon',
                      variant: 'success',
                    },
                  })
                  this.loading = false
                  this.$emit('update:is-show-modal-crop-image-banner', false)
                  this.$emit('refetch-data')
                })
                .catch(e => {
                  this.toast({
                    component: ToastificationContent,
                    props: {
                      title: e.response.data.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                  this.loading = false
                })
              // Perhaps you should add the setting appropriate file format here
            }, 'image/jpeg')
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
.rotated {
  transform: rotate(-90deg);
  /* Equal to rotateZ(45deg) */
}
</style>
